<template>
    <div>
      <serviceFee-list></serviceFee-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  
  